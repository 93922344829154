<script setup lang="ts">
import './auth.css'
import { ref } from 'vue'
import { useGeolocation } from '@vueuse/core'
import { useForm, Link, InertiaForm } from '@inertiajs/vue3'

defineProps<{
  flash?: any
}>()

const agencyStore = useAgencyStore()
const { coords, resume } = useGeolocation()

const loginForm: InertiaForm<{
  email: string | null
  password: string | null
  remember: boolean
  coords: any
}> = useForm({
  email: null,
  password: null,
  remember: false,
  coords: null
})

const showPassword = ref(false)
const isGeolocationEnabled = ref(false)

onBeforeMount(() => {
  resume()
})

onMounted(() => {
  if (navigator.geolocation) {
    // Tenta acessar a localização
    navigator.geolocation.getCurrentPosition(function() {
      isGeolocationEnabled.value = true
    }, function(error) {
      if (error.code === error.PERMISSION_DENIED) {
        isGeolocationEnabled.value = false
      }
    })
  }
})

watch(coords, (value) => {
  loginForm.coords = {
    accuracy: value.accuracy,
    latitude: value.latitude,
    longitude: value.longitude,
    altitude: value.altitude,
    altitudeAccuracy: value.altitudeAccuracy,
    heading: value.heading,
    speed: value.speed,
  }
}, { immediate: true })
</script>

<template>
  <header class="tw-w-full tw-mb-8 tw-flex tw-flex-wrap">
    <AuthOrnament title="Acessar" />
    <span class="header-subtitle">
      Use suas credenciais para acessar sua conta
    </span>
  </header>

  <section class="section-login">
    <Transition>
      <q-banner
        v-show="loginForm.hasErrors || flash.error"
        dense
        inline-actions
        class="text-white bg-red tw-rounded-md tw-w-full tw-mb-3"
      >
        <span class="tw-font-medium">
          {{ loginForm.errors.email || flash.error }}
        </span>
      </q-banner>
    </Transition>

    <form class="tw-w-full" @submit.prevent="loginForm?.post(route('login'))">
      <div class="form-control tw-mb-4">
        <q-icon name="o_mail" class="input-icon icon-left" size="1.25rem" />

        <input
          id="email"
          v-model="loginForm.email"
          type="email"
          class="input-form"
          placeholder="Digite seu e-mail"
          autocomplete="off"
          required
        />
      </div>

      <div class="form-control">
        <q-icon name="o_lock" class="input-icon icon-left" size="1.25rem" />

        <q-icon
          v-if="!showPassword"
          name="o_visibility"
          class="input-icon icon-right !tw-cursor-pointer"
          size="1.25rem"
          @click="showPassword = !showPassword"
        />
        <q-icon
          v-else
          name="o_visibility_off"
          class="input-icon icon-right !tw-cursor-pointer"
          size="1.25rem"
          @click="showPassword = !showPassword"
        />

        <input
          id="password"
          v-model="loginForm.password"
          :type="showPassword ? 'text' : 'password'"
          class="input-form"
          placeholder="Digite sua senha"
          required
        />
      </div>

      <div class="tw-w-full tw-mt-4">
        <Button
          type="submit"
          label="Entrar"
          class="full-width"
          :disable="loginForm.processing"
          :loading="loginForm.processing"
        />
      </div>
      <span class="tw-w-full tw-flex tw-justify-end tw-mt-3 tw-mb-3">
        <Link
          :href="route('password.request')"
          class="tw-cursor-pointer"
          :class="{
            'tw-text-wc-blue-400':
              !agencyStore.customizations?.pages?.auth?.linkColor,
          }"
          :style="{
            color: `${agencyStore.customizations?.pages?.auth?.linkColor} !important`,
          }"
        >
          Esqueci minha senha
        </Link>
      </span>
    </form>
  </section>
</template>
